import React from 'react';
import { styled, theme } from '../../Theme';
import Image from '../Image/Image';
import KexLink from '../KexLink/KexLink';
import { useKexNavigate } from '../KexRouter/KexRouter';
import IImageModel from '../../Assets/Models/ImageModel.interface';
import { media } from '@glitz/core';

type PropType = {
  heading: string;
  description: string;
  image: IImageModel;
  href: string;
  onMenuClose?: () => void;
  gridItemsCount?: number;
  maxRow?: number;
};

function CategoryItem({
  heading,
  description,
  image,
  href,
  onMenuClose,
  gridItemsCount = 0,
  maxRow = 4,
}: PropType) {
  const kexNavigate = useKexNavigate();

  const goToLink = () => {
    kexNavigate(href);
    onMenuClose && onMenuClose();
  };

  const gridLayoutMaxAmount = gridItemsCount > maxRow ? maxRow : gridItemsCount;

  const gridLayoutCss = {
    ...(gridItemsCount && {
      ...media(theme.mediaQuery.mediaMinLarge, {
        width: `calc((100% / ${gridLayoutMaxAmount}) - ${theme.spacing(8)}px)`,
      }),
    }),
  };

  return (
    <CategoryItemWrapper css={gridItemsCount ? gridLayoutCss : {}}>
      <StyledLink onClick={goToLink}>
        {image && (
          <StyledImage src={image.src} alt={image.alt ? image.alt : ''} />
        )}
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
      </StyledLink>
    </CategoryItemWrapper>
  );
}

export default CategoryItem;

const StyledLink = styled(KexLink, {
  display: 'block',
});

const CategoryItemWrapper = styled.div({
  display: 'block',
  width: '100%',
  margin: { xy: theme.spacing(4) },
  padding: { xy: theme.spacing(4) },
  position: 'relative',
  ':hover': {
    ':after': {
      pointerEvents: 'none',
      content: '""',
      display: 'block',
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      border: {
        xy: {
          width: theme.spacing(0.5),
          style: 'solid',
          color: theme.thinGray,
        },
      },
    },
    cursor: 'pointer',
  },
});

const Heading = styled.h3({
  font: {
    size: theme.epsilon,
    weight: theme.fontWeight.bold,
  },
  color: theme.blueDark,
  marginBottom: theme.spacing(2),
});

const Description = styled.p({
  font: {
    size: theme.beta,
  },
  lineHeight: theme.lineHeight.relaxed,
});

const StyledImage = styled(Image, {
  marginBottom: theme.spacing(4),
  width: '100%',
});
