import React, { useState } from 'react';
import AssortmentModel from './AssortmentPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { applyEditModeAttr } from '../Shared/Common/Helpers';
import { styled, StyledProps } from '@glitz/react';
import { theme } from '../Theme';
import { media } from '@glitz/core';
import XhtmlComponent from '../Shared/XhtmlComponent/XhtmlComponent';
import CategoryItem from '../Shared/Category/CategoryItem';
import useMedia from '../Shared/Hooks/useMedia';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { useHeaderData } from '../Shared/Models/Headers/Header';

function AssortmentPage() {
  const { inEditMode, header, text } = useCurrentPage<AssortmentModel>();

  const {
    translations: {
      'common/assortmentReadMore': readMore,
      'common/assortmentReadLess': readLess,
    },
  } = useAppSettingsData();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isMobile = useMedia(theme.mediaQuery.mediaMaxLarge);

  const toggleDescription = () => {
    setIsOpen(!isOpen);
  };

  const openContentStyle = {
    maxHeight: '100%',
    ':after': {
      display: 'none',
    },
    ':before': {
      display: 'none',
    },
  };

  // Use commerce sub links since we already have them
  const { commerceSubLinks } = useHeaderData();

  return (
    <>
      <Container>
        <Heading {...applyEditModeAttr(inEditMode && 'Header')}>
          {header}
        </Heading>
        <Content css={isOpen ? openContentStyle : {}}>
          <XhtmlComponent
            {...applyEditModeAttr(inEditMode && 'Text')}
            content={text}
          />
          {isMobile && (
            <StyledReadMore onClick={toggleDescription} isOpen={isOpen}>
              {isOpen ? readLess : readMore}
            </StyledReadMore>
          )}
        </Content>
      </Container>
      <styled.Div>
        <List>
          {commerceSubLinks &&
            commerceSubLinks.map((item, index) => (
              <CategoryItem
                heading={item.text}
                description={item.description ?? ''}
                image={item.image}
                href={item.href}
                gridItemsCount={3}
                key={index}
              />
            ))}
        </List>
      </styled.Div>
    </>
  );
}

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: { x: 'auto' },
  padding: {
    x: theme.spacing(4),
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: {
      x: theme.none,
    },
  }),
});

const Heading = styled.h3({
  marginBottom: theme.spacing(2),
  font: {
    weight: theme.fontWeight.bold,
    size: theme.zeta,
    family: theme.fontFamily.heading,
  },
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: {
      size: theme.eta,
    },
  }),
});

const Content = styled.div({
  position: 'relative',
  maxHeight: theme.assortmentContentMaxHeight,
  overflow: 'hidden',
  ...media(theme.mediaQuery.mediaMaxLarge, {
    ':after': {
      content: '" "',
      position: 'absolute',
      left: theme.none,
      bottom: theme.assortmentContentFadeHeight,
      width: '100%',
      height: theme.assortmentContentFadeHeight,
      backgroundImage:
        'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',
    },
    ':before': {
      content: '" "',
      position: 'absolute',
      left: theme.none,
      bottom: theme.none,
      width: '100%',
      height: theme.assortmentContentFadeHeight,
      backgroundColor: theme.white,
    },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(4),
  }),
});

const List = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  margin: { x: -theme.spacing(4) },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { x: -theme.spacing(8) },
  }),
});

const ReadMore = styled.p({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  width: '100%',
  ':hover': {
    cursor: 'pointer',
  },
});

const StyledReadMore = React.memo(
  styled(
    ({
      compose,
      isOpen = false,
      children,
      ...restProps
    }: { isOpen: boolean; children: React.ReactNode } & React.HTMLAttributes<
      HTMLParagraphElement
    > &
      StyledProps) => (
      <ReadMore
        css={compose({
          ...(!isOpen && {
            position: 'absolute',
            bottom: theme.none,
          }),
        })}
        {...restProps}
      >
        {children}
      </ReadMore>
    )
  )
);

export default AssortmentPage;
